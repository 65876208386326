import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import QRCode from "react-qr-code";

function App() {
  const [value, setValue] = useState(null);
  const [floorId, setFloorId] = useState(581)
  const changeFloor = () => {
    setFloorId(floorId === 581 ? 572 : 581)
  }
  const floorLabel = useMemo(() => floorId === 581 ? '19楼' : '10楼')
  useEffect(() => {
    fetch("https://yun.bolink.club/wit/faceuserpermissiongrouptb/selectDoorTkEr", {
      method: "POST",
      body: JSON.stringify({
        mobile: '13000000000',
        projectId: 133,
        currentTime: Date.now() / 1000,
        userId: 749636,
        expirationTime: 5,
        allList: 0,
        deviceId: 34,
        floorId: floorId,
        deviceType: 6,
        doorList:
          '[{"id":"415-189","name":"A栋通道闸","commonDevice":0,"deviceType":6,"doorFrameId":544,"channelPowerId":1}]',
      }),
      headers: {
        "Content-Type": "application/json",
      }
    }).then(res => res.json()).then(res => {
      setValue(res.data)
    })
  }, [floorId]);
  return (
    <div className="App">
      <p>{floorLabel}</p>
      {value && <QRCode size={256} value={value} onClick={changeFloor} />}
    </div>
  );
}

export default App;
